















































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { ApiResponse, Contact, Project, Structure, User, RelatedProject } from '@/interfaces'
import { InvStatuses } from '@/formHelpers'
import ContactForm from './ContactForm.vue'
import QuickNav from '@/components/QuickNav.vue'

interface StructureSelect {
  id: number | null
  name: string | null
  type: string | null
}

interface InventoryObject {
  id?: number | null
  signatory: string | null
  presence: string | null
  requerant?: string | null
  requerantCapacity: string | null
  tribunal: string | null
  jugeCommissaire: string | null
  adminJudiciaire: string | null
  jugeTaxateur: string | null
  numGreffe: string | null
  refGreffe: string | null
  numRole: string | null
  dateJugement: string | null
  dateConversion: string | null
  dateOrdonnanceVente: string | null
  dateNonRecours: string | null
  dateDepotInventaire: string | null
  dateMission: string | null
  dateInventaire: Date | string | null
  dateRDV: string | null
  dateSaisie: string | null
  dateCorrection: string | null
  dateEnvoi: string | null
  dateVente: Date | string | null
  dateImpecuniosityNotice: string | null
  inventoryId?: string | null
  structureId: number | null
  structureType: string | null
  projectId?: string | null
  manager: string | null
  nature: string | null
  status: string | null
  notes: string | null
  debiteur?: string | null
  gerant?: string | null
  directeur?: string | null
  responsableSecteur?: string | null
  bailleur?: string | null
  repreneur?: string | null
  tuteur?: string | null
  sapiteur?: string | null
  apporteurAffaire?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  parentProject?: RelatedProject | null
  childrenProjectsInfos?: null | RelatedProject[]
  externalNotes: string | null
  numDossier: string | null
}

export default Vue.extend({
  components: { ContactForm, QuickNav },
  props: {
    project: {
      type: Object as () => Project,
      required: false,
      default: () => {
        return {
          structure: {
            id: null,
          },
          manager: {
            formName: null,
            id: null,
          },
          /*
        inventory: {
          signatory: {
            id: null,
            formName: null,
          },
        },
        */
        }
      },
    },
    isDisabled: Boolean,
    saveInventoryTrigger: Boolean,
  },
  data() {
    return {
      busy: false,
      structures: [] as StructureSelect[],
      managers: [] as Record<string, string | null>[],
      signatories: [] as Record<string, string | null>[],
      debiteurs: [] as Record<string, string | null>[],
      gerants: [] as Record<string, string | null>[],
      directeurs: [] as Record<string, string | null>[],
      responsableSecteurs: [] as Record<string, string | null>[],
      bailleurs: [] as Record<string, string | null>[],
      repreneurs: [] as Record<string, string | null>[],
      tuteurs: [] as Record<string, string | null>[],
      sapiteurs: [] as Record<string, string | null>[],
      apporteurAffaires: [] as Record<string, string | null>[],
      debiteurInfos: {
        siret: '',
        vat: '',
        fullName: '',
        formName: '',
        address: '',
        email: '',
        phone: '',
        mobile: '',
        companyName: '',
      },
      requerantInfos: {
        siret: '',
        vat: '',
        fullName: '',
        formName: '',
        address: '',
        email: '',
        phone: '',
        mobile: '',
        companyName: '',
      },
      chosenDebiteur: '',
      chosenGerant: '',
      chosenRequerant: '',
      requerants: [] as Record<string, string | null>[],
      tribunals: [] as Record<string, string | null>[],
      jugesCommissaires: [] as Record<string, string | null>[],
      adminsJudiciaires: [] as Record<string, string | null>[],
      jugesTaxateurs: [] as Record<string, string | null>[],
      formModel: {
        id: null,
        signatory: null,
        presence: null,
        requerant: null,
        requerantCapacity: null,
        tribunal: null,
        jugeCommissaire: null,
        adminJudiciaire: null,
        jugeTaxateur: null,
        numGreffe: null,
        refGreffe: null,
        numRole: null,
        dateJugement: null,
        dateConversion: null,
        dateOrdonnanceVente: null,
        dateNonRecours: null,
        dateDepotInventaire: null,
        dateMission: null,
        dateInventaire: null,
        dateRDV: null,
        dateSaisie: null,
        dateCorrection: null,
        dateEnvoi: null,
        dateVente: null,
        dateImpecuniosityNotice: null,
        inventoryId: null,
        structureId: null,
        structureType: null,
        projectId: null,
        manager: null,
        nature: null,
        status: 'en_cours',
        notes: null,
        debiteur: null,
        gerant: null,
        directeur: null,
        responsableSecteur: null,
        bailleur: null,
        repreneur: null,
        tuteur: null,
        sapiteur: null,
        apporteurAffaire: null,
        createdAt: null,
        updatedAt: null,
        childrenProjectsInfos: [],
        externalNotes: null,
        numDossier: null,
      } as InventoryObject,
      formRules: {
        structure: [
          {
            required: true,
            message: 'Merci de sélectionner une structure',
            trigger: ['blur', 'change'],
          },
        ],
      },
      loading: false,
      showAddContactModal: false,
      usedContact: false,
      newContact: null as Contact | null,
      newContactType: '',
      newContactId: null as string | null,
      chosenStructureType: null as string | null,
      InvStatuses,
      natures: [],
      capacityList: null as Record<string, string>[] | null,
      viewportWidth: 0,
      showDebiteurBusinessInfos: false,
      showRequerantBusinessInfos: false,
      showParentProjectModal: false,
      projects: [] as Project[],
      parentProject: null as null | number,
      presenceNull: true,
      changeDetected: null as null | boolean,
      isFormDisabled: true,
      resetContactForm: false,
      infoPrefill: null as string | null,
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    project(newVal) {
      this.setFormModel(newVal)
      this.changeDetected = null
      //this.getRequerantCapacityList()
    },
    user(newVal) {
      if (Array.isArray(newVal.client.structures)) {
        this.structures = []
        newVal.client.structures.forEach((structure: Structure) => {
          this.structures.push({
            id: structure.id ?? null,
            name: structure.name ?? null,
            type: structure.type ?? null,
          })
        })

        // If there's only one structure, it should be selected by default
        if (this.structures.length == 1) {
          this.formModel.structureId = this.structures[0].id
        }
      }
      this.addons = newVal.client?.addons ?? 0

      // Update natures from config
      this.natures = newVal.client.config.natures
    },
    newContact(newVal) {
      if (newVal !== null) {
        switch (this.newContactType) {
          case 'requerant':
            this.updateRequerant(newVal)
            break

          case 'tribunal':
            this.updateTribunal(newVal)
            break

          case 'juge_commissaire':
            this.updateJugeCommissaire(newVal)
            break

          case 'admin_judiciaire':
            this.updateAdminJudiciaire(newVal)
            break

          case 'juge_taxateur':
            this.updateJugeTaxateur(newVal)
            break

          case 'debiteur':
            this.updateDebiteur(newVal)
            break

          case 'gerant':
            this.updateGerant(newVal)
            break

          case 'directeur':
            this.updateDirecteur(newVal)
            break

          case 'responsableSecteur':
            this.updateResponsableSecteur(newVal)
            break

          case 'bailleur':
            this.updateBailleur(newVal)
            break

          case 'repreneur':
            this.updateRepreneur(newVal)
            break

          case 'tuteur':
            this.updateTuteur(newVal)
            break

          case 'sapiteur':
            this.updateSapiteur(newVal)
            break

          case 'apporteurAffaire':
            this.updateApporteurAffaire(newVal)
            break
        }
        this.newContact = null
        this.newContactType = ''
      }
    },
    formModel: {
      deep: true,
      immediate: true,
      handler(newVal) {
        // Kind of a tricky one. We have to watch for deep changes for this reactive data as
        // we have to react to a select value and match its corresponding id to the pool of available
        // structures available, thus allowing us to chose the type.
        if (newVal.structureId) {
          const chosenStructure = this.structures.filter((o) => {
            return o.id == newVal.structureId
          })

          if (chosenStructure.length > 0) {
            this.chosenStructureType = chosenStructure[0].type
          } else {
            this.chosenStructureType = 'volontaire'
          }
        }

        // Has a structure been chosen?
        if (newVal.structureId !== null) {
          this.isFormDisabled = false
        }

        // Doing some check for the presence checkbox
        if (newVal.presence == '') {
          this.formModel.presence = null
        }

        this.presenceNull = this.formModel.presence?.length == 0 || this.formModel.presence == null

        // Mission / Sent dates automatically set for judiciary projects
        if (this.isJudiciary()) {
          if (this.formModel.dateMission !== newVal.dateJugement) {
            this.formModel.dateMission = newVal.dateJugement
          }

          if (this.formModel.dateEnvoi !== newVal.dateDepotInventaire) {
            this.formModel.dateEnvoi = newVal.dateDepotInventaire
          }
        }

        this.changeDetected = this.changeDetected !== null
      },
    },
    saveInventoryTrigger() {
      this.submit()
    },
  },
  mounted() {
    if (this.user) {
      // Update structures
      this.structures = this.user.client.structures

      // If there's only one structure, it should be selected by default
      // if (this.structures.length == 1) {
      //   this.formModel.structureId = this.structures[0].id
      // }

      // Update natures from config
      this.natures = this.user.client.config.natures

      this.addons = this.user.client?.addons ?? 0
    }

    // Préremplissage uniquement en cas de création
    if (this.$router.currentRoute.name == 'inventory_create') {
      
      // If there's only one structure, it should be selected by default
      if (this.structures.length == 1) {
        this.formModel.structureId = this.structures[0].id
      }

      // If there's only one manager, it should be selected by default
      this.loadManagers(true) // => economie de click qui coute tres tres cher si appelé meme en edit
      if (this.managers.length == 1) {
        this.formModel.manager = this.managers[0].value
      }

      // If there's only one signatory, it should be selected by default
      this.loadSignatories(true)
      if (this.signatories.length == 1) {
        this.formModel.signatory = this.signatories[0].value
      }
    }

    this.setFormModel(this.project)

    this.viewportWidth = window.innerWidth

    //this.getRequerantCapacityList()

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    setFormModel(project: Project) {
      this.busy = true
      // Structure
      this.formModel.structureId = project.structure?.id ?? null
      this.formModel.structureType = project.structure?.type ?? null

      // Status
      this.formModel.status = project.status ?? 'en_cours'

      // Managers
      this.formModel.manager = project.manager?.id?.toString() ?? null
      if (project.manager?.id) {
        this.managers = [
          {
            label: project.manager?.formName ?? '-',
            value: project.manager?.id?.toString() ?? null,
          },
        ]
      }

      // Requerants
      this.formModel.requerant = project.inventory?.requerant?.id?.toString() ?? null
      if (project.inventory?.requerant?.id) {
        this.requerants = [
          {
            label: project.inventory.requerant.formName ?? '-',
            value: project.inventory.requerant.id.toString(),
          },
        ]
        this.requerantInfos = this.setContactInfos(project.inventory.requerant, 'requerant')
      }

      // Debiteurs
      this.formModel.debiteur = project.inventory?.debiteur?.id?.toString() ?? null
      if (project.inventory?.debiteur?.id) {
        this.debiteurs = [
          {
            label: project.inventory.debiteur.formName ?? '-',
            value: project.inventory.debiteur.id.toString(),
          },
        ]

        this.debiteurInfos = this.setContactInfos(project.inventory.debiteur, 'debiteur')
      }

      // Gerants
      this.formModel.gerant = project.inventory?.gerant?.id?.toString() ?? null
      if (project.inventory?.gerant?.id) {
        this.gerants = [
          {
            label: project.inventory.gerant.formName ?? '-',
            value: project.inventory.gerant.id.toString(),
          },
        ]
      }

      // Directeurs
      this.formModel.directeur = project.inventory?.directeur?.id?.toString() ?? null
      if (project.inventory?.directeur?.id) {
        this.directeurs = [
          {
            label: project.inventory.directeur.formName ?? '-',
            value: project.inventory.directeur.id.toString(),
          },
        ]
      }

      // responsableSecteurs
      this.formModel.responsableSecteur = project.inventory?.responsableSecteur?.id?.toString() ?? null
      if (project.inventory?.responsableSecteur?.id) {
        this.responsableSecteurs = [
          {
            label: project.inventory.responsableSecteur.formName ?? '-',
            value: project.inventory.responsableSecteur.id.toString(),
          },
        ]
      }

      // bailleurs
      this.formModel.bailleur = project.inventory?.bailleur?.id?.toString() ?? null
      if (project.inventory?.bailleur?.id) {
        this.bailleurs = [
          {
            label: project.inventory.bailleur.formName ?? '-',
            value: project.inventory.bailleur.id.toString(),
          },
        ]
      }

      // repreneurs
      this.formModel.repreneur = project.inventory?.repreneur?.id?.toString() ?? null
      if (project.inventory?.repreneur?.id) {
        this.repreneurs = [
          {
            label: project.inventory.repreneur.formName ?? '-',
            value: project.inventory.repreneur.id.toString(),
          },
        ]
      }

      // tuteurs
      this.formModel.tuteur = project.inventory?.tuteur?.id?.toString() ?? null
      if (project.inventory?.tuteur?.id) {
        this.tuteurs = [
          {
            label: project.inventory.tuteur.formName ?? '-',
            value: project.inventory.tuteur.id.toString(),
          },
        ]
      }

      // sapiteurs
      this.formModel.sapiteur = project.inventory?.sapiteur?.id?.toString() ?? null
      if (project.inventory?.sapiteur?.id) {
        this.sapiteurs = [
          {
            label: project.inventory.sapiteur.formName ?? '-',
            value: project.inventory.sapiteur.id.toString(),
          },
        ]
      }

      // apporteurAffaires
      this.formModel.apporteurAffaire = project.inventory?.apporteurAffaire?.id?.toString() ?? null
      if (project.inventory?.apporteurAffaire?.id) {
        this.apporteurAffaires = [
          {
            label: project.inventory.apporteurAffaire.formName ?? '-',
            value: project.inventory.apporteurAffaire.id.toString(),
          },
        ]
      }

      // Signatories
      this.formModel.signatory = project.inventory?.signatory?.id?.toString() ?? null
      if (project.inventory?.signatory?.id) {
        this.signatories = [
          {
            label: project.inventory.signatory?.formName ?? '-',
            value: project.inventory.signatory?.id?.toString() ?? null,
          },
        ]
      }

      // Nature
      this.formModel.nature = project.inventory?.nature ?? null

      // Requerant
      this.formModel.requerant = project.inventory?.requerant?.id?.toString() ?? null
      if (project.inventory?.requerant?.id) {
        this.requerants = [
          {
            label: project.inventory.requerant.formName ?? '-',
            value: project.inventory.requerant.id.toString(),
          },
        ]

        this.formModel.requerantCapacity = project.inventory.requerantCapacity ?? null
      }

      // Tribunal
      this.formModel.tribunal = project.inventory?.tribunal?.id?.toString() ?? null
      if (project.inventory?.tribunal?.id) {
        this.tribunals = [
          {
            label: project.inventory.tribunal.formName ?? '-',
            value: project.inventory.tribunal.id.toString(),
          },
        ]
      }

      // Juge Commissaire
      this.formModel.jugeCommissaire = project.inventory?.jugeCommissaire?.id?.toString() ?? null
      if (project.inventory?.jugeCommissaire?.id) {
        this.jugesCommissaires = [
          {
            label: project.inventory.jugeCommissaire.formName ?? '-',
            value: project.inventory.jugeCommissaire.id.toString(),
          },
        ]
      }

      // Admin. Judiciaire
      this.formModel.adminJudiciaire = project.inventory?.adminJudiciaire?.id?.toString() ?? null
      if (project.inventory?.adminJudiciaire?.id) {
        this.adminsJudiciaires = [
          {
            label: project.inventory.adminJudiciaire.formName ?? '-',
            value: project.inventory.adminJudiciaire.id.toString(),
          },
        ]
      }

      // Juge taxateur
      this.formModel.jugeTaxateur = project.inventory?.jugeTaxateur?.id?.toString() ?? null
      if (project.inventory?.jugeTaxateur?.id) {
        this.jugesTaxateurs = [
          {
            label: project.inventory.jugeTaxateur.formName ?? '-',
            value: project.inventory.jugeTaxateur.id.toString(),
          },
        ]
      }

      this.formModel.presence = project.inventory?.presence ?? null
      this.formModel.numGreffe = project.inventory?.numGreffe ?? null
      this.formModel.refGreffe = project.inventory?.refGreffe ?? null
      this.formModel.numRole = project.inventory?.numRole ?? null
      this.formModel.dateJugement = project.inventory?.dateJugement ?? null
      this.formModel.dateConversion = project.inventory?.dateConversion ?? null
      this.formModel.dateOrdonnanceVente = project.inventory?.dateOrdonnanceVente ?? null
      this.formModel.dateNonRecours = project.inventory?.dateNonRecours ?? null
      this.formModel.dateDepotInventaire = project.inventory?.dateDepotInventaire ?? null
      this.formModel.dateMission = project.inventory?.dateMission ?? null
      this.formModel.dateInventaire = project.inventory?.dateInventaire ?? null
      this.formModel.dateRDV = project.inventory?.dateRDV ?? null
      this.formModel.dateSaisie = project.inventory?.dateSaisie ?? null
      this.formModel.dateCorrection = project.inventory?.dateCorrection ?? null
      this.formModel.dateEnvoi = project.inventory?.dateEnvoi ?? null
      this.formModel.dateVente = project.inventory?.dateVente ?? null
      this.formModel.dateImpecuniosityNotice = project.inventory?.dateImpecuniosityNotice ?? null
      this.formModel.inventoryId = project.inventory?.id?.toString() ?? null
      this.formModel.structureId = project.structure?.id ?? null
      this.formModel.structureType = project.structure?.type ?? null
      this.formModel.projectId = project.id?.toString() ?? null
      this.formModel.manager = project.manager?.id?.toString() ?? null
      this.formModel.nature = project.inventory?.nature ?? null
      this.formModel.status = project.status ?? null
      this.formModel.notes = project.notes ?? null
      this.formModel.externalNotes = project.inventory?.externalNotes ?? null
      this.formModel.debiteur = project.inventory?.debiteur?.id?.toString() ?? null
      this.formModel.gerant = project.inventory?.gerant?.id?.toString() ?? null
      this.formModel.directeur = project.inventory?.directeur?.id?.toString() ?? null
      this.formModel.responsableSecteur = project.inventory?.responsableSecteur?.id?.toString() ?? null
      this.formModel.bailleur = project.inventory?.bailleur?.id?.toString() ?? null
      this.formModel.repreneur = project.inventory?.repreneur?.id?.toString() ?? null
      this.formModel.tuteur = project.inventory?.tuteur?.id?.toString() ?? null
      this.formModel.sapiteur = project.inventory?.sapiteur?.id?.toString() ?? null
      this.formModel.apporteurAffaire = project.inventory?.apporteurAffaire?.id?.toString() ?? null
      this.formModel.createdAt = project.inventory?.createdAt ?? null
      this.formModel.updatedAt = project.inventory?.updatedAt ?? null
      this.formModel.parentProject = project.parentProject ?? null
      this.formModel.childrenProjectsInfos = project.childrenProjectsInfos ?? null
      this.formModel.numDossier = project.generatedNumber ?? null
      this.busy = false
      this.changeDetected = null
    },
    updatePresence() {
      if (this.presenceNull === true) {
        this.formModel.presence = ''
      }
    },
    searchDebiteurs(search?: string) {
      this.debiteurs = this.searchContacts(search)
    },
    searchRequerants(search?: string) {
      this.requerants = this.searchContacts(search)
    },
    searchGerants(search?: string) {
      this.gerants = this.searchContacts(search)
    },
    searchDirecteurs(search?: string) {
      this.directeurs = this.searchContacts(search)
    },
    searchResponsableSecteurs(search?: string) {
      this.responsableSecteurs = this.searchContacts(search)
    },
    searchBailleurs(search?: string) {
      this.bailleurs = this.searchContacts(search)
    },
    searchRepreneurs(search?: string) {
      this.repreneurs = this.searchContacts(search)
    },
    searchTuteurs(search?: string) {
      this.tuteurs = this.searchContacts(search)
    },
    searchSapiteurs(search?: string) {
      this.sapiteurs = this.searchContacts(search)
    },
    searchApporteurAffaires(search?: string) {
      this.apporteurAffaires = this.searchContacts(search)
    },
    loadTribunals(isVisible: boolean) {
      if (isVisible) this.tribunals = this.searchContacts('*', ['tribunal'])
    },
    loadJugesCommissaires(isVisible: boolean) {
      if (isVisible) this.jugesCommissaires = this.searchContacts('*', ['juge_commissaire'])
    },
    loadAdminsJudiciaires(isVisible: boolean) {
      if (isVisible) this.adminsJudiciaires = this.searchContacts('*', ['admin_judiciaire'])
    },
    loadJugesTaxateurs(isVisible: boolean) {
      if (isVisible) this.jugesTaxateurs = this.searchContacts('*', ['juge_taxateur'])
    },
    loadSignatories(isVisible: boolean) {
      if (isVisible) this.signatories = this.searchContacts('*', ['commissaire_justice'])
    },
    searchContacts(
      search?: string,
      category = null as string[] | null,
      minLength = 2
    ): Record<string, string | null>[] {
      if (search == '*' || (search && search.length >= minLength)) {
        if (search == '*') {
          search = ''
        }
        this.infoPrefill = search

        this.loading = true
        let contacts = [] as Record<string, string | null>[]
        this.$api
          .get('/contacts', {
            params: {
              search: search ?? null,
              category,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (apiResponse.data && Array.isArray(apiResponse.data)) {
              apiResponse.data.forEach((contact: Contact) => {
                let contactLabel = contact.formName
                if (contact.city) {
                  contactLabel = `${contactLabel} - ${contact.city}`
                }
                contacts.push({
                  label: contactLabel ?? null,
                  value: contact.id?.toString() ?? null,
                })
                //this.contactsInfosTemp.push(contact) // Hack used to load debiteurinfos
              })
            }
          })
          .finally(() => {
            this.loading = false
          })

        return contacts
      } else {
        return []
      }
    },
    loadManagers(isVisible: boolean) {
      if (!isVisible) return
      this.loading = true
      this.$api
        .get('/managers', {
          params: {
            search: '',
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.managers = []
          if (apiResponse.data && Array.isArray(apiResponse.data)) {
            apiResponse.data.forEach((user: User) => {
              this.managers.push({
                label: user.formName ?? null,
                value: user.id?.toString() ?? null,
              })
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    openContactForm(type: string, contactId?: string) {
      if (!this.busy && this.formModel.structureId !== null) {
        this.showAddContactModal = true
        this.newContactType = type
        this.newContactId = contactId ?? null
      }
      // eb : ajout de cette ligne car la popup affichait les informations de la personne saisit précédemment lorsqu'on faisait "jouter une contact"
      // Ca fonctionne mais à voir si le code JS ne pourrait pas être nettoyer. Il y a bcp de choses qui ne semblent pas utiles car on ne peut pas editer une fiche contact d'ici
      this.clearNewContact()
    },
    closeContactForm() {
      this.showAddContactModal = false
      if (this.newContactType === 'debiteur') {
        this.formModel.debiteur = null
      } else if (this.newContactType === 'requerant') {
        this.formModel.requerant = null
      } else if (this.newContactType === 'gerant') {
        this.formModel.gerant = null
      } else if (this.newContactType === 'directeur') {
        this.formModel.directeur = null
      } else if (this.newContactType === 'responsableSecteur') {
        this.formModel.responsableSecteur = null
      } else if (this.newContactType === 'bailleur') {
        this.formModel.bailleur = null
      } else if (this.newContactType === 'repreneur') {
        this.formModel.repreneur = null
      } else if (this.newContactType === 'tuteur') {
        this.formModel.tuteur = null
      } else if (this.newContactType === 'sapiteur') {
        this.formModel.sapiteur = null
      } else if (this.newContactType === 'apporteurAffaire') {
        this.formModel.apporteurAffaire = null
      }

      this.infoPrefill = null
    },
    clearNewContact() {
      this.newContactId = ''
      this.debiteurs = []
      this.requerants = []
      this.gerants = []
      this.directeurs = []
      this.responsableSecteurs = []
      this.bailleurs = []
      this.repreneurs = []
      this.tuteurs = []
      this.sapiteurs = []
      this.apporteurAffaires = []
      this.resetContactForm = !this.resetContactForm
      this.updateUsedContact(false)
    },
    updateUsedContact(isUsed: boolean) {
      this.usedContact = isUsed
    },
    updateNewContact(contact: Contact) {
      this.showAddContactModal = false
      this.newContact = contact
      if (this.newContactType !== '') {
        this.loadContact(contact?.id?.toString() ?? '', this.newContactType)
      }
    },
    updateContactsList(contactsList: Record<string, string | null>[], contact: Contact) {
      contactsList.length = 0
      contactsList.push({
        label: contact.formName ?? '-',
        value: contact.id?.toString() ?? null,
      })
    },
    updateRequerant(contact: Contact) {
      this.updateContactsList(this.requerants, contact)
      let sameContact = false
      if (this.formModel.requerant === this.formModel.debiteur) {
        this.formModel.debiteur = null
        sameContact = true
      }
      this.formModel.requerant = null
      this.requerantInfos = this.setContactInfos(contact, 'requerant')
      this.$nextTick(() => {
        this.formModel.requerant = contact.id?.toString() ?? null
        if (sameContact) {
          this.formModel.debiteur = contact.id?.toString() ?? null
        }
      })
    },
    updateDebiteur(contact: Contact) {
      this.updateContactsList(this.debiteurs, contact)
      let sameContact = false
      if (this.formModel.debiteur === this.formModel.requerant) {
        this.formModel.requerant = null
        sameContact = true
      }
      this.formModel.debiteur = null
      this.debiteurInfos = this.setContactInfos(contact, 'debiteur')
      this.$nextTick(() => {
        this.formModel.debiteur = contact.id?.toString() ?? null
        if (sameContact) {
          this.formModel.requerant = contact.id?.toString() ?? null
        }
      })
    },
    updateGerant(contact: Contact) {
      this.updateContactsList(this.gerants, contact)
      this.formModel.gerant = null
      this.$nextTick(() => {
        this.formModel.gerant = contact.id?.toString() ?? null
      })
    },
    updateDirecteur(contact: Contact) {
      this.updateContactsList(this.directeurs, contact)
      this.formModel.directeur = null
      this.$nextTick(() => {
        this.formModel.directeur = contact.id?.toString() ?? null
      })
    },
    updateResponsableSecteur(contact: Contact) {
      this.updateContactsList(this.responsableSecteurs, contact)
      this.formModel.responsableSecteur = null
      this.$nextTick(() => {
        this.formModel.responsableSecteur = contact.id?.toString() ?? null
      })
    },
    updateBailleur(contact: Contact) {
      this.updateContactsList(this.bailleurs, contact)
      this.formModel.bailleur = null
      this.$nextTick(() => {
        this.formModel.bailleur = contact.id?.toString() ?? null
      })
    },
    updateRepreneur(contact: Contact) {
      this.updateContactsList(this.repreneurs, contact)
      this.formModel.repreneur = null
      this.$nextTick(() => {
        this.formModel.repreneur = contact.id?.toString() ?? null
      })
    },
    updateTuteur(contact: Contact) {
      this.updateContactsList(this.tuteurs, contact)
      this.formModel.tuteur = null
      this.$nextTick(() => {
        this.formModel.tuteur = contact.id?.toString() ?? null
      })
    },
    updateSapiteur(contact: Contact) {
      this.updateContactsList(this.sapiteurs, contact)
      this.formModel.sapiteur = null
      this.$nextTick(() => {
        this.formModel.sapiteur = contact.id?.toString() ?? null
      })
    },
    updateApporteurAffaire(contact: Contact) {
      this.updateContactsList(this.apporteurAffaires, contact)
      this.formModel.apporteurAffaire = null
      this.$nextTick(() => {
        this.formModel.apporteurAffaire = contact.id?.toString() ?? null
      })
    },
    updateTribunal(contact: Contact) {
      this.updateContactsList(this.tribunals, contact)
      this.formModel.tribunal = null
      this.$nextTick(() => {
        this.formModel.tribunal = contact.id?.toString() ?? null
      })
    },
    updateJugeCommissaire(contact: Contact) {
      this.updateContactsList(this.jugesCommissaires, contact)
      this.formModel.jugeCommissaire = null
      this.$nextTick(() => {
        this.formModel.jugeCommissaire = contact.id?.toString() ?? null
      })
    },
    updateAdminJudiciaire(contact: Contact) {
      this.updateContactsList(this.adminsJudiciaires, contact)
      this.formModel.adminJudiciaire = null
      this.$nextTick(() => {
        this.formModel.adminJudiciaire = contact.id?.toString() ?? null
      })
    },
    updateJugeTaxateur(contact: Contact) {
      this.updateContactsList(this.jugesTaxateurs, contact)
      this.formModel.jugeTaxateur = null
      this.$nextTick(() => {
        this.formModel.jugeTaxateur = contact.id?.toString() ?? null
      })
    },
    loadContact(id: string, type: string) {
      if (id === 'add') {
        this.openContactForm(type)
      } else {
        if (type === 'debiteur') {
          if (!this.formModel.requerant) {
            this.$nextTick(() => {
              this.requerants = this.debiteurs
              if (this.requerants) {
                this.formModel.requerant = this.requerants?.find((contact) => contact.value === id)?.value
              }
            })
            this.loadContactInfos(id, 'requerant')
          }

          this.loadContactInfos(id, 'debiteur')
        } else {
          this.loadContactInfos(id, type)
        }
      }
    },
    setContactInfos(contact: Contact | null, type: string) {
      let address = '-'
      if (contact?.address != null) {
        address = `${contact?.address ?? ''} ${contact?.address2 ?? ''} ${contact?.postalCode ?? ''} ${contact?.city ?? ''
          }`
      }

      this.updateContactInfos(contact, type)

      return {
        siret: contact?.siret ?? '-',
        vat: contact?.vat ?? '-',
        fullName: contact?.fullName ?? '-',
        formName: contact?.formName ?? '-',
        address,
        email: contact?.email ?? '-',
        phone: contact?.phone ?? '-',
        mobile: contact?.mobile ?? '-',
        companyName: contact?.companyName ?? '-',
      }
    },
    updateContactInfos(contact: Contact | null, type: string) {
      if (type === 'debiteur') {
        this.showDebiteurBusinessInfos = false

        if (contact?.type == 'personne_morale') {
          this.showDebiteurBusinessInfos = true
        }

        this.chosenDebiteur = contact?.id?.toString() ?? ''
      } else if (type === 'requerant') {
        this.showRequerantBusinessInfos = false

        if (contact?.type == 'personne_morale') {
          this.showRequerantBusinessInfos = true
        }

        this.chosenRequerant = contact?.id?.toString() ?? ''
      }
    },
    loadContactInfos(id: string, type: string) {
      if (id == '') {
        if (type === 'debiteur') {
          this.debiteurInfos = this.setContactInfos(null, 'debiteur')
        } else if (type === 'requerant') {
          this.requerantInfos = this.setContactInfos(null, 'requerant')
        }
        return
      }
      this.busy = true
      this.$api
        .get(`/contact/${id}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          if (type === 'debiteur') {
            this.debiteurInfos = this.setContactInfos(apiResponse.data, 'debiteur')
          } else if (type === 'requerant') {
            this.requerantInfos = this.setContactInfos(apiResponse.data, 'requerant')
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    nl2br(str: string): string {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    submit() {
      if (this.changeDetected === true && !this.busy) {
        const data = {
          ...this.formModel,
        }

        if (this.formModel.inventoryId) {
          // Patching
          this.busy = true
          this.$api
            .put(`/inventory/${this.formModel.inventoryId}`, {
              ...data,
              id: parseInt(this.formModel.inventoryId),
              projectId: this.formModel.id ? this.formModel.id.toString() : '',
            })
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.setFormModel(apiResponse.data)
              this.$emit('projectUpdated', apiResponse.data)
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Inventaire modifié avec succès !',
              })
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse

                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
              this.changeDetected = false
              this.showParentProjectModal = false
            })
        } else {
          // Creating
          this.busy = true

          // Remove datas
          delete data.id // To avoid error with the API when create (cannot deserialize with id = null - must be int)
          delete data.createdAt // To avoid error with the API when create (cannot deserialize with null - must be datetime)

          this.$api
            .post('/inventory', data)
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Inventaire créé avec succès !',
              })

              this.$emit('inventoryCreated', apiResponse.data)
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse

                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
              this.changeDetected = false
              this.showParentProjectModal = false
            })
        }
      }
    },
    getRequerantCapacityList() {
      // On la charge si pas déjà chargée
      if (this.capacityList == null) {
        this.capacityList = []
        //this.busy = true
        this.$api
          .get(`/inventory/requerant-capacity`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            apiResponse.data.forEach((place: string) => {
              this.capacityList?.push({ value: place })
            })
          })
          // .finally(() => {
          //   this.busy = false
          // })
      }
    },
    queryRequerantCapacitySearch(queryString: string, cb: CallableFunction) {
      // On la charge à la demande et pas a l'init de la page
      this.getRequerantCapacityList()
      const places = this.capacityList ?? []
      const results = queryString ? places.filter(this.createFilter(queryString)) : places
      cb(results)
    },
    createFilter(queryString: string) {
      return (str: Record<string, string>) => {
        return str.value?.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ?? null
      }
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
      } else {
        return '-'
      }
    },
    getProjectType(project: Project | null) {
      return project === null || !project ? 'is-unknown' : this.getStructureType(project.structure?.type ?? '')
    },
    getRelatedProjectType(project: RelatedProject | null) {
      return project === null || !project ? 'is-unknown' : this.getStructureType(project.structureType ?? '')
    },
    getStructureType(structureType: string) {
      switch (structureType) {
        case 'volontaire':
          return 'is-volontary'

        case 'judiciaire':
          return 'is-judiciary'

        default:
          return 'is-unknown'
      }
    },
    getProjectTitle(project: Project): string {
      return `${project?.generatedNumber ?? '-'} - ${project?.inventory?.debiteur?.formName ?? '-'}`
    },
    searchInventories(query: string): Project[] {
      if (query.length >= 3) {
        this.projects = []
        this.$api
          .get(`/inventories`, {
            params: {
              search: query,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            apiResponse.data.forEach((project: Project) => {
              this.projects.push(project)
            })
          })
      }

      return this.projects
    },
    cancelParentProject() {
      this.parentProject = null
      this.showParentProjectModal = false
    },
    setParentProject() {
      const parentProject = this.projects.filter((project) => project.id == this.parentProject)[0] as Project
      this.formModel.parentProject = this.getParentProjectData(parentProject) ?? null
      this.changeDetected = true
      this.submit()
    },
    getParentProjectData(project: Project) {
      return {
        id: project.id,
        generatedNumber: project.generatedNumber,
        inventoryId: project.inventory?.id ?? null,
        requisitionId: project.requisition?.id ?? null,
        structureType: project.structure?.type ?? null,
      }
    },
    getChildProjectRoute(childProject: RelatedProject) {
      if (childProject.requisitionId) {
        return 'requisition_edit'
      } else if (childProject.inventoryId) {
        return 'inventory_edit'
      }
      return 'inventory_edit'
    },
    getChildProjectRouteParams(childProject: RelatedProject) {
      if (childProject.requisitionId) {
        return {
          projectId: childProject.id ? childProject.id.toString() : '',
          requisitionId: childProject.requisitionId ? childProject.requisitionId.toString() : '',
        }
      } else if (childProject.inventoryId) {
        return {
          projectId: childProject.id ? childProject.id.toString() : '',
          inventoryId: childProject.inventoryId ? childProject.inventoryId.toString() : '',
        }
      }
      return {
        projectId: '',
        inventoryId: '',
      }
    },
    isJudiciary() {
      let result = false

      this.structures.forEach((structure) => {
        if (structure.id == this.formModel.structureId && structure.type == 'judiciaire') {
          result = true
        }
      })

      return result
    },
    isExistedProject(project: Project) {
      return project.structure?.id ?? null
    },
  },
})
